import { type ExternalResponse, type Response } from './get-shipments';
import {
  type ExternalModalities,
  type Modalities,
  type Modality,
  type ExternalExpressModality,
} from './model';

const MODALITIES_TYPES: Record<keyof ExternalModalities, Modality['type']> = {
  express: 'express',
  marketplace_express: 'marketplaceExpress',
  marketplace_scheduled: 'marketplaceScheduled',
  scheduled: 'scheduled',
  standard: 'standard',
};

function findCheapestModality(modalities: Modalities): Modality {
  return modalities.reduce((previous, current) => {
    if (previous.cost > current.cost) {
      return current;
    }

    return previous;
  }, modalities.find((modality) => modality.type === 'standard') as Modality);
}

export default function adapter(externalResponse: ExternalResponse) {
  const response: Response = {
    data: externalResponse.data.reduce<Response['data']>(
      (
        previous,
        {
          skuId: externalSkuId,
          modalities: externalModalities,
          pickupInStore: externalPickupInStore,
        }
      ) => {
        const skuId = externalSkuId ?? '';

        const modalities = (
          Object.entries(externalModalities) as [
            keyof ExternalModalities,
            ExternalExpressModality
          ][]
        ).map<Modality>(([key, modality]) => ({
          type: MODALITIES_TYPES[key],
          ...modality,
        }));

        previous[skuId] = {
          skuId,
          modalities,
          chepeastModality: findCheapestModality(modalities),
          pickupInStore: externalPickupInStore && {
            type: 'pickUpInStore',
            ...externalPickupInStore,
          },
        };

        return previous;
      },
      {}
    ),
  };

  return response;
}
