import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';
import { type Seller } from '@/infra/services/catalog/product/get-sellers/model';

import adapter from './adapter';
import { type Product } from './model';

export type Params = {
  itemId: ItemId;
};

export type ExternalResponse = {
  data: {
    product: Product;
    currentSeller?: Seller;
  };
};

export type Response = {
  data: {
    product: Product;
    currentSeller: Seller;
  };
};

const getProductInfo: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/products/{itemId}',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    return adapter(response);
  };

export default getProductInfo;
