import { type HttpClient } from '@/application/interfaces/http-client';

import { getContent } from './get-content';
import { getFacets } from './get-facets';

const SearchService = (httpClient: HttpClient.Instance) => ({
  getContent: getContent(httpClient),
  getFacets: getFacets(httpClient),
});

export default SearchService;
