import { type LoggerOptions, type Logger } from 'pino';

import {
  logger as baseFactory,
  type customLevels,
} from '@/../next-logger.config';

type Factory<T extends string> = (options?: LoggerOptions) => Logger<T>;

type LoggerFn = (message: string, context?: Record<string, any>) => void;

type CustomLevels = keyof typeof customLevels;

const factory: Factory<CustomLevels> = baseFactory;

type Levels =
  | 'debug'
  | 'info'
  | 'notice'
  | 'warning'
  | 'error'
  | 'critical'
  | 'alert'
  | 'emergency';

export const customLogger = factory({
  enabled: process.env.NODE_ENV !== 'test',
});

export const logger: Record<Levels, LoggerFn> = {
  debug: (message, context) => customLogger.debug({ context }, message),
  info: (message, context) => customLogger.info({ context }, message),
  notice: (message, context) => customLogger.notice({ context }, message),
  warning: (message, context) => customLogger.warn({ context }, message),
  error: (message, context) => customLogger.error({ context }, message),
  critical: (message, context) => customLogger.fatal({ context }, message),
  alert: (message, context) => customLogger.alert({ context }, message),
  emergency: (message, context) => customLogger.emergency({ context }, message),
};
