import { type ExternalResponse, type Response } from './get-product-info';

export default function adapter(externalResponse: ExternalResponse) {
  const response: Response = {
    data: {
      ...externalResponse.data,
      currentSeller: externalResponse.data.currentSeller ?? {
        id: '',
        assistedSalesScopes: {
          canConsultBatchSearch: false,
          canConsultStock: false,
          canPurchase: false,
        },
        ecommerceScopes: {
          canConsultEcommerceStock: false,
          canPickupInStore: false,
          canPurchase: false,
        },
        isFreeShipping: false,
        isOutlet: false,
        quantityLimitMessage: null,
        selected: false,
        shop: {
          id: '',
          logo: '',
          name: '',
        },
        skuInstance: null,
        pricing: {
          discount: {
            featureInstallmentDiscount: false,
            paymentMethods: null,
            percentage: 0,
            type: '',
          },
          installment: {
            brandedQuantity: 0,
            brandedTotalValue: 0,
            brandedValue: 0,
            quantity: 0,
            totalValue: 0,
            value: 0,
          },
          price: { to: 0, from: 0, regionPrice: 0, suggested: 0 },
        },
      },
    },
  };

  return response;
}
