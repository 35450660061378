import { type ServiceMethod } from '@/application/interfaces/service';

import { type CategoryPageData } from './models';

export type Response = {
  data: CategoryPageData;
};

export type SearchParams = {
  category: string;
};

const getFacets: ServiceMethod<Response, SearchParams> =
  (httpClient) =>
  async ({ category }, options) => {
    const { body } = await httpClient.get<Response, undefined, SearchParams>(
      '/api/v3/category/attributes',
      {
        ...options,
        searchParams: {
          category,
        },
      }
    );

    return body;
  };

export default getFacets;
