import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';

import adapter from './adapter';
import { type Shipment, type ExternalShipment } from './model';

export type SearchParams = {
  zipCode: string;
};

export type Params = {
  productId: ItemId;
};

export type Response = {
  data: Partial<Record<string, Shipment>>;
};

export type ExternalResponse = {
  data: ExternalShipment[];
};

type Data = Params & SearchParams;

const getShipments: ServiceMethod<Response, Data> =
  (httpClient) => async (data, options) => {
    const { productId, zipCode } = data;

    const { body: response } = await httpClient.get<
      ExternalResponse,
      Params,
      SearchParams
    >('/api/v3/products/{productId}/shipments', {
      ...options,
      params: {
        productId,
      },
      searchParams: {
        zipCode,
      },
    });

    return adapter(response);
  };

export default getShipments;
