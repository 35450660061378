import { type ServiceMethod } from '@/application/interfaces/service';

export type MetaTag = {
  type: string;
  value: string;
  'data-page-type'?: string;
};

export type Video = {
  id: string;
  title: string;
  url: string;
  picture: string;
};

export type Content = {
  id: string;
  title: string;
  url: string;
  picture: string;
};

export type Tags = {
  legacy: string[];
  wp: string[];
};

export type PageContent = {
  title: string;
  metaTags: MetaTag[][];
  canonical: string;
  gaTrack: string;
  prev: string | null;
  next: string | null;
  topDescription: string;
  middleDescription: string;
  footerDescription: string;
  video: Video[];
  contents: Content[];
  tags: Tags;
};

export type Response = {
  data: PageContent;
};

export type GetContentSearchParams = {
  productsPerPage: number;
  totalOfProducts: number;
  url: string;
  slug: string;
};

type SearchParams = {
  nbHits: number;
  category: string;
  hitsPerPage: number;
  url: string;
};

const getContent: ServiceMethod<Response, GetContentSearchParams> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, undefined, SearchParams>(
      '/api/v3/category/content',
      {
        ...options,
        searchParams: {
          url: data.url,
          category: data.slug,
          nbHits: data.totalOfProducts,
          hitsPerPage: data.productsPerPage,
        },
      }
    );

    return body;
  };

export default getContent;
