import { isBrowser } from '@backyard-ui/utils';
import { datadogRum } from '@datadog/browser-rum';

import { logger } from '../logger';

type LoggerFn = (
  error: unknown,
  context?: Record<string, any>
) => Promise<void>;

type IErrorReporter = Record<'report', LoggerFn>;

export const ErrorReporter: IErrorReporter = {
  async report(error, context) {
    const finalError = await buildError(error);

    isBrowser
      ? datadogRum.addError(finalError, context)
      : logger.error(finalError.message, {
          ...context,
          err: {
            name: finalError.name,
            stack: finalError.stack,
            cause: finalError.cause,
          },
        });
  },
};

/**
 * In a try/catch, the type of error is unknown.
 * This function will build an Error object from the unknown error.
 */
const buildError = async (maybeError: unknown) => {
  if (!maybeError) return new Error('Unknown error');

  if (maybeError instanceof Error) return maybeError;

  if (typeof maybeError === 'string') return new Error(maybeError);

  if (maybeError instanceof Response) {
    return new Error(
      `HTTP Error: ${maybeError.status} - ${maybeError.statusText}`,
      {
        cause: await maybeError.json().catch(() => null),
      }
    );
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
};
