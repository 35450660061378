import { type HttpClient } from '@/application/interfaces/http-client';

import { getBreadcrumb } from './get-breadcumb';
import { getCrosses } from './get-crosses';
import { getProductInfo } from './get-product-info';
import { getRegionByZipCode } from './get-region-by-zip-code';
import { getSellers } from './get-sellers';
import { getShipments } from './get-shipments';
import { getVariants } from './get-variants';

const ProductService = (httpClient: HttpClient.Instance) => ({
  getBreadcrumb: getBreadcrumb(httpClient),
  getCrosses: getCrosses(httpClient),
  getProductInfo: getProductInfo(httpClient),
  getRegionByZipCode: getRegionByZipCode(httpClient),
  getSellers: getSellers(httpClient),
  getShipments: getShipments(httpClient),
  getVariants: getVariants(httpClient),
});

export default ProductService;
