import { type ServiceMethod } from '@/application/interfaces/service';

import adapter from './adapter';
import { type ZipCodeRegion } from './model';

export type Params = {
  zipCode: string;
};

export type Response = {
  data: ZipCodeRegion;
};

export type ExternalResponse = ZipCodeRegion;

const getRegionByZipCode: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { zipCode } = data;

    const { body: response } = await httpClient.get<ExternalResponse, Params>(
      '/api/boitata/v1/zipcode/{zipCode}',
      {
        ...options,
        params: {
          zipCode,
        },
      }
    );

    return adapter(response);
  };

export default getRegionByZipCode;
