import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';

import { type Cross } from './model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: Cross[];
};

const getCrosses: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response, status } = await httpClient.get<Response, Params>(
      '/api/v3/crosses/{itemId}/products',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    if (status === 204) {
      return {
        data: [],
      };
    }

    return response;
  };

export default getCrosses;
