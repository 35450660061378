import { isBrowser } from '@backyard-ui/utils';

import { type HttpClient } from '@/application/interfaces/http-client';
import {
  AB_TESTING,
  API_TOKEN,
  IS_ON_MOBILE_APP,
  REGION,
  TOTEM,
} from '@/utils/constants/cookies';
import {
  AB_TESTING_HEADER,
  AUTHORIZATION_HEADER,
  MOBILE_APP_HEADER,
  REGION_HEADER,
  TOTEM_HEADER,
} from '@/utils/constants/headers';
import { cookies } from '@/utils/cookies';

import { addDeviceHeader } from './modifiers/add-device-header';
import { addPreviewTrueQuery } from './modifiers/add-preview-true-query';

const COOKIES_TO_HEADER_MAP = {
  [AB_TESTING]: AB_TESTING_HEADER,
  [IS_ON_MOBILE_APP]: MOBILE_APP_HEADER,
  [REGION]: REGION_HEADER,
  [TOTEM]: TOTEM_HEADER,
  [API_TOKEN]: AUTHORIZATION_HEADER,
};

type CookiesToHeaderMapKey = keyof typeof COOKIES_TO_HEADER_MAP;

const requestInterceptor: HttpClient.RequestInterceptor = async (options) => {
  options.headers = options.headers || {};

  const getCookies = isBrowser
    ? cookies.client.getMany
    : cookies.server.getMany;

  const cookiesToGet = Object.keys(
    COOKIES_TO_HEADER_MAP
  ) as CookiesToHeaderMapKey[];

  const cookiesArray = await getCookies<CookiesToHeaderMapKey>(cookiesToGet);

  for (const cookieObject of cookiesArray) {
    const headerName = COOKIES_TO_HEADER_MAP[cookieObject.name];

    if (cookieObject.value) {
      const value =
        cookieObject.name === API_TOKEN
          ? `Bearer ${cookieObject.value}`
          : cookieObject.value;

      options.headers[headerName] = value;
    }
  }

  await addDeviceHeader(options);

  await addPreviewTrueQuery(options);

  return options;
};

export default requestInterceptor;
