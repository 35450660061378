const pino = require('pino');

const customLevels = {
  notice: 35,
  alert: 70,
  emergency: 80,
};

const factory = (defaultConfig) =>
  pino({
    ...defaultConfig,
    messageKey: 'message',
    timestamp: pino.stdTimeFunctions.isoTime,
    customLevels,
    mixin() {
      return {
        hostname: process.env.HOSTNAME,
        env: process.env.ENV,
        bu_code: process.env.BU_CODE,
      };
    },
    browser: {
      write: (object) => {
        return console.log(
          JSON.stringify(object, (key, value) => {
            if (['level', 'time'].includes(key)) return undefined;

            return value;
          })
        );
      },
    },
  });

module.exports = { logger: factory, customLevels };
